import {isRejectedWithValue} from '@reduxjs/toolkit'
import {navigationRoutes} from 'navigation/NavigationRouter'
import {matchPath} from 'react-router-dom'

const rtkQueryErrorHandler = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        const isOutRoute = navigationRoutes.some(
            (route) =>
                route.type.name === 'OutRoute' &&
                route.props.path !== '/' &&
                matchPath(window.location.pathname, route.props.path)
        )

        console.warn('We got a rejected action!', {
            action,
            api,
            isOutRoute,
        })

        if (action.payload.status === 401 && !isOutRoute) {
            window.location.href = '/login'
        }
    }

    return next(action)
}

export default rtkQueryErrorHandler
