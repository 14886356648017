import {createSlice} from '@reduxjs/toolkit'
import {i18n} from 'common'
import {useSelector} from 'react-redux'

const initialState = {
    account: null,
    language: null,
    page: {},
    sort: {},
    confirmModal: false,
    globalConfig: [],
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAccount(state, action) {
            state.account = action.payload
        },
        setLanguage(state, action) {
            state.language = action.payload
        },
        setPurchaseData(state, action) {
            // if (!state.purchaseData) {
            //     state.purchaseData = []
            // }

            state.purchaseData = action.payload
        },
        setSortKey(state, action) {
            // if (!state.sort) {
            //     state.sort = {}
            // }

            state.sort.key = action.payload.key
            state.sort.order = action.payload.order
        },
        setPagination(state, action) {
            state.page.page = action.payload.page
            state.page.size = action.payload.size
        },
        setWeeklyRange(state, action) {
            state.weeklyRange = action.payload
        },
        setMonthlyRange(state, action) {
            state.monthlyRange = action.payload
        },
        setConfirmModal(state, action) {
            state.confirmModal = action.payload
        },
        setGlobalConfig(state, action) {
            state.globalConfig = action.payload
        },
    },
})

export const {
    setAccount,
    setLanguage,
    setPurchaseData,
    setSortKey,
    setPagination,
    setWeeklyRange,
    setMonthlyRange,
    setConfirmModal,
    setGlobalConfig,
} = appSlice.actions
export default appSlice.reducer

export const useAccount = () => useSelector((state) => state.app.account)

export const useLanguage = () => useSelector((state) => state.app.language)

export const usePurchaseData = () =>
    useSelector((state) => state.app.purchaseData || [])

export const useSortKey = () => useSelector((state) => state.app.sort || {})

export const usePagination = () => useSelector((state) => state.app.page || {})

export const useWeeklyRange = () =>
    useSelector((state) => state.app.weeklyRange)

export const useMonthlyRange = () =>
    useSelector((state) => state.app.monthlyRange)

export const useIsRtl = () =>
    useSelector((state) => state.app.language === 'he')

export const useI18n = () =>
    useSelector((state) => ({
        language: state.app.language,
        i18n,
    }))

export const useConfirmModal = () =>
    useSelector((state) => state.app.confirmModal)

export const useGlobalConfig = () => {
    const globalConfig = useSelector((state) => state.app.globalConfig)
    const getByName = (name) =>
        globalConfig?.find(
            (gc) =>
                gc.name?.toString()?.toLowerCase() ===
                name?.toString()?.toLowerCase()
        )

    const getLanguageOptions = () => {
        const languages = getByName('language')?.data
        return Object.keys(languages).map((key) => ({
            value: key,
            label: languages[key],
        }))
    }

    return {
        globalConfig,
        getByName,
        getLanguageOptions,
    }
}
