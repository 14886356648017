import React, {useEffect} from 'react'

import {BrowserRouter as Router} from 'react-router-dom'
import loadable from '@loadable/component'

import RouteManager from 'navigation/RouteManager'
import {Colors} from 'appearance'
import {ConfigProvider, Spin} from 'antd'
import {
    setGlobalConfig,
    useGetGlobalConfigQuery,
    useGlobalConfig,
    useIsRtl,
} from 'store/Store'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {useDispatch} from 'react-redux'

const loadableWithSpin = (componentImport) =>
    loadable(componentImport, {
        fallback: (
            <Spin tip="Loading..." size="large" className="mx-auto block py-4">
                <div className="w-full h-screen flex justify-center items-center"></div>
            </Spin>
        ),
    })

//screens
const Dashboard = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'dashboard' */ 'screens/Dashboard'
    )
)
const Login = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'login' */ 'screens/Login'
    )
)
const Register = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'register' */ 'screens/Register'
    )
)
const ForgotPassword = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'forgot-password' */ 'screens/ForgotPassword'
    )
)
const ResetPassword = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'reset-password' */ 'screens/ResetPassword'
    )
)
const Accounts = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'accounts' */ 'screens/Accounts'
    )
)
const Account = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'account' */ 'screens/Account'
    )
)
const Users = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'users' */ 'screens/newScreens/Users'
    )
)
const User = loadableWithSpin(() =>
    import(/* webpackPrefetch: true, webpackChunkName:'user' */ 'screens/User')
)
const Plans = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'plans' */ 'screens/Plans'
    )
)

const Programs = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'program' */ 'screens/Programs'
    )
)
const Rewards = loadableWithSpin(() =>
    import(
        /* webpackPrefetch: true, webpackChunkName:'rewards' */ 'screens/Rewards'
    )
)

const ProtectedRoute = RouteManager.ProtectedRoute
const OutRoute = RouteManager.OutRoute

const theme = {
    token: {
        colorPrimary: Colors.PRIMARY_COLOR,
        colorBgLayout: Colors.APP_WHITE,
        fontFamily: `'Open Sans', sans-serif`,
    },
    components: {
        Layout: {
            // colorBgHeader: Colors.APP_WHITE,
            headerBg: Colors.APP_WHITE,
        },
        Menu: {
            // colorItemBg: Colors.APP_WHITE,
            itemBg: Colors.APP_WHITE,
            // colorItemBgSelected: 'transparent',
            itemSelectedBg: 'transparent',
            // colorItemText: Colors.APP_GRAY_DARK,
            itemColor: Colors.APP_GRAY_DARK,
            // colorItemTextSelected: Colors.APP_WHITE,
            itemSelectedColor: Colors.APP_WHITE,
        },
        Checkbox: {
            colorBorder: Colors.APP_BLACK,
            lineWidth: 1.5,
            lineHeight: 3,
        },
    },
}

const navigationRouter = (
    <Router>
        <OutRoute exact path="/" component={Login} />
        <OutRoute exact path="/login" component={Login}></OutRoute>
        <OutRoute path="/forgotPassword" component={ForgotPassword}></OutRoute>
        <OutRoute path="/register/:token" component={Register}></OutRoute>
        <OutRoute
            path="/resetPassword/:token"
            component={ResetPassword}></OutRoute>
        <ProtectedRoute
            path="/dashboard"
            component={Dashboard}></ProtectedRoute>
        <ProtectedRoute path="/accounts" component={Accounts}></ProtectedRoute>
        <ProtectedRoute
            path="/account/:accountId"
            component={Account}></ProtectedRoute>
        <ProtectedRoute path="/users" component={Users}></ProtectedRoute>
        <ProtectedRoute path="/user/:userId" component={User}></ProtectedRoute>
        <ProtectedRoute
            path="/plans/:accountProgramId"
            component={Plans}></ProtectedRoute>
        <ProtectedRoute path="/programs" component={Programs}></ProtectedRoute>
        <ProtectedRoute path="/rewards" component={Rewards}></ProtectedRoute>
    </Router>
)

export const navigationRoutes = navigationRouter.props.children

const NavigationRouter = () => {
    const dispatch = useDispatch()
    const isRtl = useIsRtl()
    const {globalConfig} = useGlobalConfig()

    const {data: configData} = useGetGlobalConfigQuery(
        globalConfig?.length ? skipToken : {}
    )

    useEffect(() => {
        if (!globalConfig?.length && configData) {
            dispatch(setGlobalConfig(configData?.data))
        }
    }, [configData, dispatch, globalConfig])

    return (
        <ConfigProvider theme={theme} direction={isRtl ? 'rtl' : 'ltr'}>
            {navigationRouter}
        </ConfigProvider>
    )
}

export default NavigationRouter
