const en = {
    dir: 'ltr',
    header: {
        search: 'Search',
        search_account: 'Search Account',
        search_user: 'Search User',
    },
    hello: 'Hello',
    choose_an_account: 'Choose an account',
    login: {
        header: 'Welcome to HYGEAR!',
        sub_header: 'Enter your email address and password',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm Password',
        forgot_password: 'Forgot password',
        login_button: 'Send',
        submit_button: 'Send',
        forgot_password_header:
            'Need to translate - login.forgot_password_header',
        forgot_password_button:
            'Need to translate - login.forgot_password_button',
    },
    forgot_password: {
        header: 'Change Password',
        sub_header: 'Please enter the email you used to log in',
        user_not_found: 'Email not found!',
        email_sent:
            'Thanks! We will send you an email with a link to set a new password',
        submit_button: 'Send',
    },
    reset_password: {
        header: 'Change Password',
        password_changed: 'Your password has been changed',
        action: 'Ok',
        submit_button: 'Send',
    },
    register: {
        header: 'Welcome, please enter a password',
        submit_button: 'Submit',
    },
    profileMenu: {
        changePassword: 'Change Password',
        adminsList: 'Admin List',
        logout: 'Logout',
    },
    menu: {
        dashboard: 'Dashboard',
        organizations: 'Organizations',
        organization: 'Organization',
        animals: 'Animals',
        all_mail: 'All mail',
        trash: 'Trash',
        spam: 'Spam',
        users: 'Users',
        accounts: 'Accounts',
        weekly_view: 'Weekly view',
        monthly_view: 'Monthly view',
        groups: 'Groups',
        plans: 'Plans',
        programs: 'Programs',
        rewards: 'Rewards',
        challenges: 'Challenges',
        settings: 'Settings',
        help: 'Help',
    },
    activity_diversity: {
        title: 'Activities',
        sub_title: 'Most popular activities',
        actions: 'Need to translate - activity_diversity.actions',
    },
    pulse: {
        title: 'Aerobic workouts analysis',
        sub_title: 'Pulse-based analysis of aerobic activity',
        actions: 'Activities',
        activities_use: {
            inactive: 'Not active',
            moderate: 'Moderate',
            highly_active: 'Very active',
        },
    },
    workout_plan: {
        title: 'Workout Plan',
        sub_title: '',
        categories: {
            power: 'Strength',
            aerobic: 'Aerobic',
            steps: 'Steps',
        },
        weekly_view: {
            title: '',
            sub_title:
                'To what degree do participants perform their daily goals?',
        },
        monthly_view: {
            title: '',
            sub_title: 'Consistency over the past 4 weeks',
            xAxisTitle: 'week %',
            yAxisTitle: 'Participation rate',
        },
        goal_rates: {
            all_goals: 'Need to translate - workout_plan.goal_rates.all_goals',
            minimum_one_fulfilled:
                'Need to translate - workout_plan.goal_rates.minimum_one_fulfilled',
            partial_goals:
                'Need to translate - workout_plan.goal_rates.partial_goals',
            none_fulfilled:
                'Need to translate - workout_plan.goal_rates.none_fulfilled',
        },

        accomplishments: {
            title: '',
            sub_title: 'Do participants finish their workouts?',
            started: 'Workouts begun',
            completed: 'Workouts completed',
        },
    },
    strength_practice_quality: {
        legend: {
            above_target:
                'Need to translate - strength_practice_quality.legend.above_target',
            below_target:
                'Need to translate - strength_practice_quality.legend.below_target',
            in_target:
                'Need to translate - strength_practice_quality.legend.in_target',
        },
        title: 'Strength workouts analysis',
        sub_title: 'Repetitions distribution by resistance',
    },
    activity_type: {
        legend: {
            geargo: 'GearGo',
            hyrope: 'HyRoap',
            rope: 'Need to translate - activity_type.legend.rope',
            ball: 'Ball games',
            run: 'Running',
            bicycle: 'Bicycle',
            walk: 'Walking',
            other: 'Other',
        },
    },
    form: {
        update: 'Update',
        create: 'Create',
        link: 'link',
        add: 'Add',
        keyError: 'valid {{key}} is required.',
        not: 'Not',
        done: 'Done',
        send: 'Send',
        submit: 'Submit',
        uploadFile: 'Upload File',
        reuploadFile: 'Reupload file',
        formatCsv: 'format: csv',
        ok: 'Ok',
    },
    general: {
        invalid_password: 'Password must contain a minimum of 8 characters',
        invalid_verify_password: 'Passwords do not match',
    },
    tables: {
        users: 'Users',
        invites: 'Invites',
        accounts: 'Accounts',
        total_text: '{{rangeStart}}-{{rangeEnd}} of {{total}} items',
        pagination_page_size: 'number of lines in a page',
        pagination_total_text: '{{rangeStart}}-{{rangeEnd}} of {{total}}',
        table_title: '{{title}} ({{total}})',
        action_delete: 'Delete',
        action_edit: 'Edit',
    },
    accounts: {
        _id: 'ID',
        name: 'Account',
        apiKey: 'Api Key',
        created: 'Created',
        updated: 'Updated',
        role: 'Role',
        confirmed: 'Confirmed',
        image: 'Logo',
        contact_name: 'Contact Person',
        contact_email: 'Contact Email',
        language: 'Language',
        username: 'Username',
        email: 'Email',
        numberOfUsers: 'Number of Users',
        add_button_text: 'New account',
        add_form_title: 'Add an account',
        edit_form_title: 'Update account',
        actives: 'Active Accounts',
        archiveds: 'Archived Accounts',
    },
    account: {
        language: {en: 'English', he: 'Hebrew'},
        name: 'Account name',
        image: 'Logo (optional)',
        contact_name: 'Admin name (optional)',
        contact_email: 'Admin email (optional)',
    },

    users: {
        _id: 'ID',
        name: 'Name',
        email: 'E-mail',
        created: 'Created',
        role: 'Role',
        phone: ' Phone',
        inviteDate: 'Invite date',
        inviteType: 'Invite type',
        persistenceRate: 'Persistence rate',
        weeklyGoals: 'Weekly goals',
        workoutPlan: 'Workout plan',
        departmentAndRole: 'Department and role',
        abnormalHeartRate: 'Pulse alert',

        users: 'Subscribers',
        invites: 'Invited',
        admins: 'Admins',
        trainings: 'Training Data',
        profiles: 'Personal Information',
        historys: 'Data History',
        table_title: 'Subscribers',
        idNumber: 'Personal No.',
        firstName: 'Name',
        // persistenceThisMonth: 'Consistency',
        persistenceThisMonth: 'Adherence',
        activeThisWeek: 'Active week',
        program: 'Stage in plan',
        grade: 'Phys. score',
        persistenceThisMonth_tooltip: 'Consistency',
        activeThisWeek_tooltip: 'Active week',
        edit_form_title: 'Editing user data',
        delete_confirm_modal_title: 'Delete user?',
        delete_confirm_modal_subtitle:
            'Data associated with a deleted user will be lost for good if you delete it.',
        delete_confirm_modal_button_ok: 'Yes, delete',
        delete_confirm_modal_button_cancel: 'No, don’t delete',

        edit_confirm_modal_title: 'Change this user?',
        edit_confirm_modal_subtitle:
            'Attention! Maximum heart rate value exceeds age-based max. heart rate',
        edit_confirm_modal_button_ok: 'Yes, Change',
        edit_confirm_modal_button_cancel: 'No, don’t Change',
    },

    programs: {
        table_title: 'Account Programs',
        page_title: 'Create a training program',
        page_sub_title: ['A list of programs is available for each account.'],
        add_button_text: 'Add Program',
        add_form_title: 'Adding a program',
        edit_form_title: 'Edit a program',
        // FOR TABLE
        _id: 'ID',
        name: 'Program Name',
        duration: 'Duration',
        level: 'Level',
        device_requirements: 'Device requirements',
        price: 'Price',

        // FORM
        is_active_form: 'Active',
        name_form: 'Program Name',
        name_error: 'Input program name',
        display_name_form: 'Program Display Name',
        display_name_error: 'Input program display name',
        description_form: 'Description',
        description_error: 'Input program description',
        level_form: 'Level',
        strength_level_form: 'Strength Level',
        cardio_level_form: 'Cardio Level',
        level_error: 'Input program level',
        price_form: 'Price',
        price_error: 'Input program price',
        featured_form: 'Featured',
        image_url_form: 'Image URL',
        product_id_form: 'Product ID',
        product_url_form: 'Product URL',
        rating_form: 'Rating',
        reviews_form: 'Reviews',
        author_form: 'Author',
        author_image_form: 'Author Image',

        // Button
        add_confirm_modal_title: 'Add this program to the account?',
        add_confirm_modal_subtitle:
            'Be aware that all users will be affected once this program is added. Carefully review all parameters prior to confirming.',
        add_confirm_modal_button_ok: 'Yes, add Program into Account',
        add_confirm_modal_button_cancel: 'No, go back',
        edit_confirm_modal_title: 'Change this program to the account?',
        edit_confirm_modal_subtitle:
            'Be aware that all users will be affected once this program is added. Carefully review all parameters prior to confirming.',
        edit_confirm_modal_button_ok: 'Yes, change Program Account',
        edit_confirm_modal_button_cancel: 'No, go back',
        delete_confirm_modal_title: 'Change this program?',
        delete_confirm_modal_subtitle:
            "Attention! deletion of a program in the current account may have an impact on the application's users. It is strongly recommended to refrain from deleting stages.",
        delete_confirm_modal_button_ok: 'Yes, Change',
        delete_confirm_modal_button_cancel: 'No, don’t Change',
    },

    plans: {
        table_title: 'Program Stages',
        page_title: 'Create a training plan',
        add_form_title: 'Adding a stage',
        edit_form_title: 'Edit a stage',
        page_sub_title: [
            'A training program consists of stages increasing in difficulty.',
            'Here you can define them, determine their duration, and set conditions for their completion.',
        ],
        add_button_text: 'Add Stage',
        add_button_disable_tooltip:
            'Plan has reached maximum stage amount of 5. Please delete one or more stage item to add a new one.',
        _id: 'ID',
        display_name: 'Collection',
        program: 'Collection',
        level: 'Phase',
        power_target: 'Strength goal',
        aerobic_target: 'Aerobic goal',
        steps_target: 'Steps goal',
        level_form: 'Stage name',
        totalAerobicDaysTarget: 'Aerobic goal',
        totalPowerDaysTarget: 'Strength goal',
        totalStepsDaysTarget: 'Steps goal',
        minimum_duration: 'Minimal length',
        minimum_streak: 'Required streak',
        program_error: 'Select a program',
        level_error: 'Select a level',
        minimum_duration_error: 'Select minimum duration',
        minimum_streak_error: 'Select minimum streak',
        workouts: ' workouts',
        weeks: {
            one: '1 week',
            other: '{{count}} weeks',
        },
        min_weight: 'Minimum threshold (in kg)',
        max_weight: 'Maximum threshold (in kg)',
        start_date: 'Plan Start Date',
        add_confirm_modal_title: 'Add this phase to the plan?',
        add_confirm_modal_subtitle:
            'Be aware that all users will be affected once this phase is added. Carefully review all parameters prior to confirming.',
        add_confirm_modal_button_ok: 'Yes, add phase',
        add_confirm_modal_button_cancel: 'No, go back',
        edit_confirm_modal_title: 'Change this stage?',
        edit_confirm_modal_subtitle:
            "Attention! any modification of the stages in the current program may have an impact on the application's users. It is strongly recommended to refrain from making any changes to the existing program.",
        edit_confirm_modal_button_ok: 'Yes, Change',
        edit_confirm_modal_button_cancel: 'No, don’t Change',
        delete_confirm_modal_title: 'Change this stage?',
        delete_confirm_modal_subtitle:
            "Attention! deletion of a stage in the current program may have an impact on the application's users. It is strongly recommended to refrain from deleting stages.",
        delete_confirm_modal_button_ok: 'Yes, Change',
        delete_confirm_modal_button_cancel: 'No, don’t Change',
    },
    invites: {
        _id: 'ID',
        name: 'Name',
        email: 'E-mail',
        created: 'Created',
        role: 'Role',
        shirtSize: 'Shirt size',
        functionalScore: 'Functional score',

        table_title: 'Invited',
        status: 'Status',
        firstName: 'Name',
        permissions: 'Permission level',
        phone: 'Phone number',
        password: 'Password',
        confirm_password: 'Confirm Password',
        updated: 'Invitation date',
        inviteType: 'Invited as',
        idNumber: 'Personal number',
        add_button_text: 'Invite users',
        user: 'User',
        admin: 'Admin',
        cellphone: 'Cellphone',
        add_form_title: 'Invitation',
        create_password_form_title: 'Create Password',
        password_success_created: 'Password is successfully created',
        addBulk_form_title: 'Bulk User Invitation',
        update_info: 'Update details',
        send_reminder: 'Send reminder',
        create_password: 'Create password',
        import: 'Import from Excel',
        manual_add: 'Manual add',
        invite_completed: 'Need to translate - invites.invite_completed',
        invite_completed_success:
            'Need to translate - invites.invite_completed_success',
        invites: 'Need to translate - invites.invites',
        birthday: 'Date of birth',
        height: 'Height',
        weight: 'Weight',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        download_template: 'Download template',
        download_template_eng: 'Download English version template',

        add_confirm_modal_title: 'Invite this user?',
        add_confirm_modal_subtitle:
            'Attention! Maximum heart rate value exceeds age-based max. heart rate',
        add_confirm_modal_button_ok: 'Yes, Invite',
        add_confirm_modal_button_cancel: 'No, don’t Invite',
        edit_form_title: 'Edit Invitation',
        error_invalid_file: 'Invalid file, Please upload a CSV file',
        error_missing_fields: 'File is missing required headers',
        error_required_name: 'Name is required',
        error_string_name: 'Name must be a string',
        error_required_email: 'Email is required',
        error_valid_email: 'Email is no valid',
        error_valid_phone: 'Phone number please enter a valid number',
        error_valid_personal_number:
            'Personal number please enter a valid number',
        error_required_birthdate: 'Date of birth is required',
        error_valid_birthdate: 'Date of birth format should be YYYY-MM-DD',
        error_valid_gender: 'Gender must be Male, Female, or None.',
        error_valid_height: 'Height must be a number',
        error_valid_weight: 'Weight must be a number',
        error_valid_shirt_size:
            'Shirt size must be XS, S, M, L, XL, XXL, or XXXL.',
        error_valid_functional_score:
            'Functional Score must be between 1 and 5',
        error_upload_file: 'Please upload a file',
        error_upload_title: 'Your upload was failed',
        error_upload_description:
            'We were unable to process your file due to one or more errors. Please check and fix the errros below by re-upload your file.',
        error_upload_template:
            'Make sure you fill and upload file from the correct template',
        error_password_exists_empty: 'Password must be at least 8 characters!',
    },
    admins: {
        table_title: 'Admins',
        name: 'First Name',
        email: 'Email',
        permissions: 'Permissions',
        permissions_option_admin:
            'Need to translate - admins.permissions_option_admin',
    },
    organizations: {
        _id: 'ID',
        created: 'Created',
        updated: 'Updated',
        description: 'Description',
        name: 'Name',
        order: 'Order',
        address: 'Address',
        area: 'Area',
        contact: 'Contact',
        email: 'Email',
        facebookUrl: 'Facebook URL',
        homeUrl: 'Home Url',
        isactive: 'Active',
        lastActive: 'Last Active',
        lastAdminMsg: 'Last Admin Message',
        logoUrl: 'Logo URL',
        password: 'Password',
        phone: 'Phone',
        times: 'Times',
        username: 'Username',
    },
    usersFunnel: {
        registered: {
            title: 'Subscribers',
            notes: 'Joined so far',
        },
        participants: {
            title: 'Participants',
            notes: 'Opened the app',
        },
        active: {
            title: 'Active users',
            notes: 'Completed at least 1 daily goal',
        },
        excel: {
            title: 'Top performers',
            notes: 'Completed all weekly workouts',
        },
        thisweek: ' this week',
        thismonth: 'this month',
    },
    dashboard: {
        switchView: {
            subscribers: 'All Subscribers',
            participants: 'All Participants',
        },
        viewPeriod: {
            week: 'Weekly view',
            month: 'Monthly view',
        },
        workoutCategories: {
            power: 'Strength',
            strength: 'Strength',
            aerobic: 'Aerobic',
            steps: 'Steps',
        },
        bullets: {
            all: 'Completed all weekly workouts',
            minimum: 'Need to translate - dashboard.bullets.minimum',
            partial: 'Completed at least 1 workout',
            none: "Started but didn't complete",
            never: 'Did not start any workout',
            geargo: 'GEAR GO',
            rope: 'Need to translate - dashboard.bullets.rope',
            hyrope: 'HYROPE',
            ball: 'Ball games',
            run: 'Running',
            bicycle: 'Bicycle',
            walk: 'Walking',
            other: 'Other',
            above_target: 'Reps higher than resistant target',
            below_target: 'Reps under resistant target',
            in_target: 'Reps within resistant target range',
        },
    },
    fileUpload: {
        altMessage: 'click to add file',
        requirementMessage:
            'Max 10 MB | PNG or JPEG | Size : 987px X 1170px | Minimum of 72 dpi',
    },
    imageCell: {
        altMessage: 'invalid link',
    },
    rewards: {
        rewards: 'Products',
        purchases: 'Purchases',
        sents: 'Sent',
        table_title: 'Products',
        image: '',
        name: 'Product',
        description: 'Description',
        coin_required: 'Cost',
        level_requirement: 'Purchase requirements',
        created: 'Created on',
        is_displayed: 'Display in store',
        add_button_text: 'Add a Product',
        add_form_title: 'Adding a product',
        add_button_disable_tooltip:
            'No plan phase detected. Please add a plan phase in Plans page',
        edit_form_title: 'Edit a product',
        delete_confirm_modal_title: 'Delete product?',
        delete_confirm_modal_subtitle:
            'Once you delete this item it will no longer be displayed in the shop',
        delete_confirm_modal_button_ok: 'Yes, delete',
        delete_confirm_modal_button_cancel: 'No, don’t delete',
    },
    product: {
        name: 'Product name',
        short_name: 'Short name',
        description: 'Description',
        image: 'Image',
        value: 'Cost in NIS',
        purchaseRequirement: 'Purchase requirement (by stage in plan)',
        is_displayed: 'Display this product in store?',
    },
    purchases: {
        table_title: 'Purchases',
        image: '',
        checkbox: '',
        name: 'Product',
        purchase_date: 'Purchase date',
        buyers_name: "Buyer's name",
        personal_number: 'Personal no.',
        email: 'Email',
        size: 'Shirt size',
        collection_point: 'Rewards collection point',
        add_button_text: 'Move to sent',
    },
    sents: {
        table_title: 'Sent',
        image: '',
        name: 'Product',
        purchase_date: 'Purchase date',
        buyers_name: "Buyer's name",
        personal_number: 'Personal no.',
        email: 'Email',
        size: 'Shirt size',
        collection_point: 'Rewards collection point',
    },
}

export default en
