const colors = {
    WHITE: '#fff',
    APP_WHITE: '#fefefe',
    APP_BLACK: '#000',
    APP_GRAY: '#ccc',
    APP_GRAY_LIGHT: '#FAFBFD',
    APP_GRAY_DARK: '#939393',
    APP_BLUE: '#5389f3',
    PRIMARY_COLOR: '#27bbc1',
    PRIMARY_TEXT: '#333333',
    BORDER: '#dcdcdc',
    ERROR_TEXT: '#b46c6c',
    REGULAR_TEXT: '#333333',
    SUBTITLE_TEXT: '#8C8C8C',
    DISABLED_ICON: '#A4A4A4',
    DESCRIPTION_TEXT: '#939393',
    LEGEND_FONT_COLOR: '#333333',
    BULLETS_FONT_COLOR: '#939393',
    DATES_FONT_COLOR: '#939393',

    PAGE_BACKGROUND: '#fa2',
    CARD_BACKGROUND: '#fff',
    CARD_SHADOW: 'rgba(51,51,51,1.08)',
    CARD_SEPARATOR: '#e5e4e5',

    RADIO_BUTTON_SELECTED_BACKGROUND: '#f5f7fd',
    RADIO_BUTTON_SELECTED_FONT_COLOR: '#333333',
    RADIO_BUTTON_IDLE_BACKGROUND: '#fffff',
    RADIO_BUTTON_IDLE_FONT_COLOR: '#939393',

    TOOLTIP_BOX_SHADOW: '0px 4px 10px rgba(51, 51, 51, 0.12)',
    IMAGE_STYLE: {borderRadius: 21},

    ACTIVITY_TYPE_COLORS: {
        geargo: '#113fd1',
        hyrope: '#fde1e3',
        rope: '#fde1e3',
        ball: '#c89bf2',
        run: '#5389f3',
        bicycle: '#27bbc1',
        walk: '#b0ebeb',
        other: '#c6d8fb',
    },

    ACCOMPLISHMENTS: {
        started: '#c6d8fb',
        completed: '#5389f3',
    },

    GRAPH_BACKGROUND_COLOR: '#fff',
    GRAPH_GRID_COLOR: '#EEF1F0',

    FILTER_GRADIENT_COLOR: {
        left: '#5181fd',
        center: '#7399fb',
        right: '#49bcc1',
    },
    STRENGTH_PRACTICE_QUALITY_COLOR: {
        below_target: '#113fd1',
        in_target: '#5389f3',
        above_target: '#c6d8fb',
    },
    BULLETS: {
        all: '#113fd1',
        partial: '#5389f3',
        none: '#c6d8fb',
        never: '#dcdcdc',
        //--------------------
        geargo: '#113fd1',
        hyrope: '#fde1e3',
        rope: '#fde1e3',
        ball: '#c89bf2',
        run: '#5389f3',
        bicycle: '#27bbc1',
        walk: '#b0ebeb',
        other: '#c6d8fb',
        //---------------------
        below_target: '#c6d8fb',
        in_target: '#5389f3',
        above_target: '#113fd1',
    },
}

export default colors
