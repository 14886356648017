import baseApi, {transformResponseFromData} from 'store/slices/api/baseApi'

const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query({
            query: () => ({url: 'auth/getCurrentAdmin', method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: ['CurrentUser'],
        }),
        login: build.mutation({
            query: ({email, password, isAdmin}) => {
                return {
                    url: `auth/login`,
                    method: 'POST',
                    body: {email, password, isAdmin},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        logout: build.mutation({
            query: () => ({
                url: `auth/logout`,
                method: 'POST',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        signup: build.mutation({
            query: ({accountId, ...body}) => {
                console.log('signup mutation req', body, accountId)
                return {
                    url: `admin/auth/signUpFromInvite/${accountId}`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        signupWithInvite: build.mutation({
            query: ({submittedData, token}) => {
                const {email, password} = submittedData
                return {
                    url: `auth/signup/:token`,
                    method: 'POST',
                    body: {email, password, token},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        forgotPassword: build.mutation({
            query: ({email}) => {
                console.log('signup mutation req', {email})
                return {
                    url: `admin/auth/forgotPassword`,
                    method: 'POST',
                    body: {email},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        resetPassword: build.mutation({
            query: ({password, token}) => {
                console.log('signup mutation req', {password, token})
                return {
                    url: 'admin/auth/resetPassword',
                    method: 'POST',
                    body: {password, token},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        setUserAccount: build.mutation({
            query: ({accountId, accountName}) => {
                console.log('switch currentUser over to', {
                    accountId,
                    accountName,
                })
                return {
                    url: 'auth/setUserAccount',
                    method: 'POST',
                    body: {accountId, accountName},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: [
                'CurrentUser',
                {type: 'User', id: 'LIST'},
                {type: 'Invite', id: 'LIST'},
            ],
        }),
    }),
    overrideExisting: false,
})

export default authApi

export const {
    useLoginMutation,
    useGetCurrentUserQuery,
    useLogoutMutation,
    useSignupMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useSignupWithInviteMutation,
    useSetUserAccountMutation,
} = authApi
