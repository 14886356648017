import PURCHASE_HISTORY from 'mockData/purchaseData'
import PRODUCTS from 'mockData/rewardsData'
import baseApi, {
    transformResponseFromData,
    provideTags,
    provideTag,
    getPaginationSearchAndSortString,
} from 'store/slices/api/baseApi'

const rewardsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getRewards: build.query({
            query: ({account_id, page, pageSize, search, sort, order}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize,
                        search,
                        sort,
                        order
                    )
                return {
                    url: `admin/reward/product/all/${account_id}?${paginationSearchAndSortString}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTags('Rewards', PRODUCTS),
        }),
        getRewardById: build.query({
            query: (rewardId) => ({
                url: `admin/reward/product/${rewardId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Rewards', result),
        }),
        getPurchases: build.query({
            query: ({account_id, page, pageSize, search, sort, itemName}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize,
                        search,
                        sort
                    )

                const status =
                    itemName === 'sent' ? 'delivered' : 'ready_for_delivery'

                return {
                    url: `admin/reward/purchases/${account_id}?${paginationSearchAndSortString}&status=${status}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTags('Purchase', PURCHASE_HISTORY),
        }),
        createReward: build.mutation({
            query: (body) => {
                // console.log('input body:', body)
                return {
                    url: `admin/reward/product`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTags('Account', result),
            invalidatesTags: ['Rewards'],
        }),
        editRewardById: build.mutation({
            query: (reward) => {
                return {
                    url: `admin/reward/product/${reward._id}`,
                    method: 'PUT',
                    body: reward,
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTag('Account', result),
            invalidatesTags: ['Rewards'],
        }),
        // uploadProductImage: build.mutation({
        //     query: (data) => {
        //         const formData = new FormData()
        //         formData.append('file', data.file)
        //         return {
        //             url: `accounts/uploadImage`,
        //             method: 'POST',
        //             body: formData,
        //             headers: {
        //                 Accept: 'multipart/form-data',
        //             },
        //         }
        //     },
        //     transformResponse: transformResponseFromData,
        //     //invalidatesTags: (result) => provideTag('Account', result),
        //     invalidatesTags: ['Rewards'],
        // }),
        updateRewardDisplay: build.mutation({
            query: (reward) => {
                return {
                    url: `admin/reward/product/${reward._id || reward.key}`,
                    method: 'PATCH',
                    body: {
                        is_displayed: reward.is_displayed,
                    },
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTag('Account', result),
            invalidatesTags: ['Purchase'],
        }),
        updatePurchaseStatus: build.mutation({
            query: (purchase) => {
                // console.log('purchase data', purchase)
                return {
                    url: `admin/reward/purchases/deliver`,
                    method: 'PATCH',
                    body: purchase,
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTag('Account', result),
            invalidatesTags: ['Purchase'],
        }),
        deleteReward: build.mutation({
            query: (id) => {
                // console.log('delete query input:', id)
                return {
                    url: `admin/reward/product/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Rewards'),
        }),
        overrideExisting: false,
        getExportedCsvUserRewards: build.mutation({
            query: (accountId) => ({
                url: `admin/reward/purchases/csv/${accountId}`,
                method: 'GET',
            }),
        }),
    }),
})

export default rewardsApi

export const {
    useGetRewardsQuery,
    useGetRewardByIdQuery,
    useGetPurchasesQuery,
    useCreateRewardMutation,
    useEditRewardByIdMutation,
    // useUploadProductImageMutation,
    useUpdateRewardDisplayMutation,
    useUpdatePurchaseStatusMutation,
    useDeleteRewardMutation,
    useGetExportedCsvUserRewardsMutation,
} = rewardsApi
