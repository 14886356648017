import baseApi, {
    transformResponseFromData,
    provideTag,
    provideTags,
    getPaginationSearchAndSortString,
} from 'store/slices/api/baseApi'

const usersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: ({account_id, page, pageSize, search, sort}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page || 0,
                        pageSize || 20,
                        search,
                        sort
                    )

                return {
                    url: `admin/user/widget/${account_id}?${paginationSearchAndSortString}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTags('User'),
        }),
        getAdmins: build.query({
            query: ({account_id, page, pageSize, search, sort}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize,
                        search,
                        sort
                    )

                return {
                    url: `admin/user/admin/${account_id}?${paginationSearchAndSortString}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTags('User'),
        }),
        getUser: build.query({
            query: (userId) => ({
                url: `admin/user/data/${userId}/${sessionStorage.getItem(
                    'accountId'
                )}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('User', result),
        }),
        getUserStats: build.query({
            query: ({userId, period}) => {
                const range = period
                    ?.replace('(', '')
                    .replace(')', '')
                    .split(' - ')
                    .map((date) => {
                        return date.split('/').reverse().join('-')
                    })

                const statisticsEndPoint = userId
                    ? `admin/user-statistics/${userId}`
                    : 'admin/user-statistics'
                return {
                    url: range
                        ? `${statisticsEndPoint}?startDate=${range[0]}&endDate=${range[1]}`
                        : statisticsEndPoint,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('User', result),
        }),
        saveUser: build.mutation({
            query: (user) => {
                user.birthday = new Date(user.birthday).getTime()
                return {
                    url: `admin/user/data/${user._id}`,
                    method: 'PUT',
                    body: user,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('User', result),
        }),
        deleteUser: build.mutation({
            query: (id) => {
                return {
                    url: `admin/user/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
        downloadWeeklyGoalReport: build.mutation({
            query: ({accountId}) => {
                return {
                    url: `admin/user/csv/weekly-goal`,
                    method: 'POST',
                    body: {accountId},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
        downloadUserStatisticsReport: build.mutation({
            query: ({accountId}) => {
                return {
                    url: `admin/user/csv/user-statistics`,
                    method: 'POST',
                    body: {accountId},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
    }),
    overrideExisting: false,
})

export default usersApi

export const {
    useGetUserQuery,
    useGetUsersQuery,
    useGetUserStatsQuery,
    useSaveUserMutation,
    useDeleteUserMutation,
    useGetAdminsQuery,
    useDownloadWeeklyGoalReportMutation,
    useDownloadUserStatisticsReportMutation,
} = usersApi
