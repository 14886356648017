import {DATE_VIEW_PERIODS} from 'constants/dashboard'
import moment from 'moment'
import {startOfWeek} from 'date-fns'
// calculate first saturday :
// ------------------------
// if month - first saturday --> count 6 days before
// if week - last saturday --> count 6 days before
export const getDisplayDatesCaption = (viewPeriod, statisticsDates) => {
    if (viewPeriod && statisticsDates) {
        const lastSaturday = moment(statisticsDates[0], 'YYYY-MM-DD')
        let firstSaturday = lastSaturday
        if (viewPeriod === 'month') {
            firstSaturday = moment(statisticsDates[3], 'YYYY-MM-DD')
        }
        const firstSunday = moment(firstSaturday)
            .subtract(6, 'day')
            .format('DD/MM/YYYY')

        return `(${lastSaturday.format('DD/MM/YYYY')} - ${firstSunday})`
    }

    return ''
}

export const getWeekly = () => {
    const thisDate = new Date()
    const prevDate = new Date(
        thisDate.getFullYear(),
        thisDate.getMonth(),
        thisDate.getDate() - 7
    )
    const prevSaturday = moment(prevDate).endOf('week').format('DD/MM/YYYY')
    const prevSunday = moment(prevDate).startOf('week').format('DD/MM/YYYY')

    return `(${prevSunday} - ${prevSaturday})`
}

export const getMonthly = () => {
    const thisDate = new Date()
    const prevDate = new Date(
        thisDate.getFullYear(),
        thisDate.getMonth(),
        thisDate.getDate() - 7
    )
    const prevSaturday = moment(prevDate).endOf('week').format('DD/MM/YYYY')
    const lastSunday = moment(
        new Date(
            prevDate.getFullYear(),
            prevDate.getMonth(),
            prevDate.getDate() - 21
        )
    )
        .startOf('week')
        .format('DD/MM/YYYY')

    return `(${lastSunday} - ${prevSaturday})`
}

export const defaultWeeklyRange = [
    new Date(
        moment(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
            )
        ).startOf('week')
    ),
    new Date(
        moment(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
            )
        ).endOf('week')
    ),
]
export const defaultMonthlyRange = [
    new Date(
        moment(
            new Date(
                startOfWeek(new Date()).getFullYear(),
                startOfWeek(new Date()).getMonth(),
                startOfWeek(new Date()).getDate() - 28
            )
        ).startOf('week')._i
    ),
    new Date(
        moment(
            new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
            )
        ).endOf('week')
    ),
]

export const getDifferencePercentage = (value, prevValue) => {
    const percentage = Math.round(((value - prevValue) / prevValue) * 100)
    const negate = percentage < 0 ? '-' : ''
    return `${Math.abs(percentage)}%${negate}`
}

export const calculatePercentage = (value, totalValue) =>
    totalValue > 0 ? Math.round((value * 100) / totalValue) : totalValue

// reverse Bar chart position under certain languages
export const valuePos = (data, locale) => {
    return locale === 'he' ? data * -1 : data
}

export const getDecayedNumber = (num) => {
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1)}M`
    } else if (num >= 1000) {
        return `${(num / 1000).toFixed(1)}K`
    }
    return num
}

export const getChartData = (
    fullStatisticData,
    chartType,
    periodicType = DATE_VIEW_PERIODS[0]
) => {
    // if monthly - we need to reduce process an array
    console.log(`rebuild dashboard data , parameters :  ${chartType} `, {
        fullStatisticData,
        chartType,
        periodicType,
    })
    const tempData = fullStatisticData && fullStatisticData[chartType][0]
    if (tempData) {
        const {_id, date, ...dataForChart} = {
            ...tempData,
            registered: 102,
        }

        console.log(`rebuild dashboard data , ${chartType} -`, dataForChart)
        return dataForChart
    }

    return {}
}
export const isCurrentUserSuperAdmin = (userData) =>
    userData?.isSuperAdmin || false

export const isInfiniteNumber = (numerator, denominator) => {
    const result = numerator / denominator

    if (result === Infinity || result === -Infinity) {
        return true
    } else {
        return false
    }
}
