import baseApi, {
    transformResponseFromData,
    // transformResponseFromMessage,
    provideTags,
    provideTag,
    getPaginationSearchAndSortString,
} from 'store/slices/api/baseApi'

const accountsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAccountStatistics: build.query({
            query: (accountId) => {
                const statisticsEndPoint = accountId
                    ? `admin/statistics/${accountId}`
                    : 'admin/statistics'
                return {
                    url: statisticsEndPoint,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Account', result),
        }),
        getAccountDashboardStats: build.query({
            query: ({accountId, weeklyDate}) => {
                const range = weeklyDate
                    ?.replace('(', '')
                    .replace(')', '')
                    .split(' - ')
                    .map((date) => {
                        return date.split('/').reverse().join('-')
                    })

                const statisticsEndPoint = accountId
                    ? `admin/dashboard/${accountId}`
                    : 'admin/dashboard'
                return {
                    url: range
                        ? `${statisticsEndPoint}?startDate=${range[0]}&endDate=${range[1]}`
                        : statisticsEndPoint,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Account', result),
        }),
        getAccountMonthlyStatistics: build.query({
            query: (accountId) => {
                const statisticsEndPoint = accountId
                    ? `admin/statistics/monthly/${accountId}`
                    : 'admin/statistics/monthly'
                return {
                    url: statisticsEndPoint,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Account', result),
        }),
        getAccountStrengthStat: build.query({
            query: ({accountId, period}) => {
                // console.log(accountId)
                const statsEndpoint = accountId
                    ? `analytics/strength/${accountId}?text=${period}`
                    : `analytics/strength?text=${period}`
                return {
                    url: statsEndpoint,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            provideTags: (result) => provideTag('StrengthStats', result),
        }),
        getAccounts: build.query({
            query: ({page, pageSize, search, sort, contact_email, order}) => {
                let paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize,
                        search,
                        sort,
                        order
                    )

                if (contact_email) {
                    paginationSearchAndSortString += `&contact_email=${contact_email}`
                }

                return {
                    //url: 'accounts'
                    url: `accounts?${paginationSearchAndSortString}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTags('Account', result),
        }),
        getArchivedAccounts: build.query({
            query: ({page, pageSize, search, sort}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize
                        // search,
                        // sort
                    )

                return {
                    //url: 'accounts'
                    url: `accounts?${paginationSearchAndSortString}&archived=true`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTags('Account', result),
        }),
        getAccount: build.query({
            query: (accountId) => {
                return {
                    url: `accounts/${accountId}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Account', result),
        }),
        getAccountByUserEmail: build.query({
            query: ({userEmail, page, pageSize}) => {
                const params = getPaginationSearchAndSortString(page, pageSize)
                return {
                    url: `accounts/user/${userEmail}?${params}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Account', result),
        }),
        createAccount: build.mutation({
            query: (body) => {
                return {
                    url: `accounts`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTags('Account', result),
            invalidatesTags: ['Account'],
        }),
        saveAccount: build.mutation({
            query: (account) => {
                return {
                    url: `accounts/${account._id}`,
                    method: 'PUT',
                    body: account,
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTag('Account', result),
            invalidatesTags: ['Account'],
        }),
        deleteAccount: build.mutation({
            query: (id) => {
                return {
                    url: `accounts/softDelete/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Account'),
        }),
        inviteToAccount: build.mutation({
            query: ({accountId, userId, role}) => {
                return {
                    url: `accounts/invite`,
                    method: 'POST',
                    body: {accountId, userId, role},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Account', result),
        }),
        confirmAccount: build.mutation({
            query: ({accountId}) => {
                return {
                    url: `accounts/confirm/${accountId}`,
                    method: 'POST',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Invites'], //(result) => provideTag('Account', result),
        }),
        uploadAccountImage: build.mutation({
            query: (data) => {
                const formData = new FormData()
                formData.append('file', data.file)
                return {
                    url: `accounts/uploadImage`,
                    method: 'POST',
                    body: formData,
                    headers: {
                        Accept: 'multipart/form-data',
                    },
                }
            },
            transformResponse: transformResponseFromData,
            //invalidatesTags: (result) => provideTag('Account', result),
            invalidatesTags: ['Account'],
        }),
        getAccountPrograms: build.query({
            query: ({account_id, page, pageSize, search, sort}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize,
                        search,
                        sort
                    )

                return {
                    url: `accounts/account-programs/${account_id}?${paginationSearchAndSortString}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) =>
                provideTags('AccountProgram', {data: result}),
        }),
        getAccountProgramById: build.query({
            query: ({account_id, account_program_id, language = 'en'}) => ({
                url: `accounts/account-programs/${account_id}/${account_program_id}?language=${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('AccountProgram', result),
        }),
        updateAccountProgram: build.mutation({
            query: ({account_id, account_program_id, data}) => {
                return {
                    url: `accounts/account-programs/${account_id}/${account_program_id}`,
                    method: 'PUT',
                    body: data,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['AccountProgram'],
        }),
        addAccountProgram: build.mutation({
            query: ({account_id, data}) => {
                return {
                    url: `accounts/account-programs/${account_id}`,
                    method: 'POST',
                    body: data,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['AccountProgram'],
        }),
        deleteAccountProgram: build.mutation({
            query: ({account_id, account_program_id}) => ({
                url: `accounts/account-programs/${account_id}/${account_program_id}`,
                method: 'DELETE',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: ['AccountProgram'],
        }),
    }),
    overrideExisting: false,
})

export default accountsApi

export const {
    useGetAccountStatisticsQuery,
    useGetAccountDashboardStatsQuery,
    useGetAccountMonthlyStatisticsQuery,
    useGetAccountQuery,
    useGetArchivedAccountsQuery,
    useGetAccountByUserEmailQuery,
    useGetAccountsQuery,
    useGetAccountStrengthStatQuery,
    useCreateAccountMutation,
    useSaveAccountMutation,
    useDeleteAccountMutation,
    useInviteToAccountMutation,
    useConfirmAccountMutation,
    useUploadAccountImageMutation,
    useGetAccountProgramsQuery,
    useGetAccountProgramByIdQuery,
    useUpdateAccountProgramMutation,
    useAddAccountProgramMutation,
    useDeleteAccountProgramMutation,
} = accountsApi
