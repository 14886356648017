import {styled} from 'common'
import Colors from 'appearance/colors'
import 'assets/css/fonts.css'

const RegularText = styled.span`
    font-family: 'Open Sans', sans-serif;
`

const MediumText = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
`

const BoldText = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
`

const Title = styled(BoldText)`
    color: ${Colors.PRIMARY_TEXT};
    font-size: 24px;
`

const Texts = {RegularText, MediumText, BoldText, Title}
export default Texts
