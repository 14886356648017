import ARROW_LEFT from 'assets/images/arrow_left_gray.png'
import LOGO from 'assets/images/hygear-logo.png'
import LOGO2 from 'assets/images/logo2.png'
import CRYING_EMOJI from 'assets/images/Crying-Face-emoji.png'
import STAR_STRUCK from 'assets/images/Star-Struck-emoji.png'
import {ReactComponent as HELP_ICON} from 'assets/images/help-icon.svg'
import {ReactComponent as CHALLENGES_ICON} from 'assets/images/challenges-icon.svg'
import {ReactComponent as GROUPS_ICON} from 'assets/images/groups-icon.svg'
import {ReactComponent as REWARDS_ICON} from 'assets/images/rewards-icon.svg'
import {ReactComponent as SETTINGS_ICON} from 'assets/images/settings-icon.svg'
import {ReactComponent as TRAININGS_ICON} from 'assets/images/trainings-icon.svg'
import {ReactComponent as USERS_ICON} from 'assets/images/users-icon.svg'
import {ReactComponent as DASHBOARD_ICON} from 'assets/images/dashboard-icon.svg'
import {ReactComponent as DELETE_ICON} from 'assets/images/delete-icon.svg'
import {ReactComponent as ARROW_DROP_DOWN} from 'assets/images/arrow_drop_down.svg'
import {ReactComponent as SEARCH_ICON} from 'assets/images/search-icon.svg'
import {ReactComponent as SORT_ICON} from 'assets/images/sort-icon.svg'
import {ReactComponent as INFO_ICON} from 'assets/images/info-icon.svg'
import {ReactComponent as DONE_ICON} from 'assets/images/done-icon.svg'
import {ReactComponent as WARNING_ICON} from 'assets/images/warning-icon.svg'
import {ReactComponent as MORE_ICON} from 'assets/images/more-icon.svg'
import {ReactComponent as EDIT_ICON} from 'assets/images/edit-icon.svg'
import {ReactComponent as SORT_UP_ICON} from 'assets/images/sort-up-icon.svg'
import {ReactComponent as SORT_DOWN_ICON} from 'assets/images/sort-down-icon.svg'
import {ReactComponent as IMAGE_NOT_FOUND_ICON} from 'assets/images/image-not-found-icon.svg'
import {ReactComponent as CHANGE_PASSWORD_ICON} from 'assets/images/change_password.svg'
import {ReactComponent as ADMINS_LIST_ICON} from 'assets/images/admins_list.svg'
import {ReactComponent as LOGOUT_ICON} from 'assets/images/logout.svg'
import {ReactComponent as STEPS_LOGO} from 'assets/images/steps-icon.svg'

const Images = {
    ARROW_LEFT,
    LOGO,
    LOGO2,
    HELP_ICON,
    CHALLENGES_ICON,
    GROUPS_ICON,
    REWARDS_ICON,
    SETTINGS_ICON,
    TRAININGS_ICON,
    USERS_ICON,
    DASHBOARD_ICON,
    DELETE_ICON,
    ARROW_DROP_DOWN,
    SEARCH_ICON,
    INFO_ICON,
    SORT_ICON,
    DONE_ICON,
    WARNING_ICON,
    MORE_ICON,
    EDIT_ICON,
    SORT_UP_ICON,
    SORT_DOWN_ICON,
    IMAGE_NOT_FOUND_ICON,
    CHANGE_PASSWORD_ICON,
    ADMINS_LIST_ICON,
    LOGOUT_ICON,
    CRYING_EMOJI,
    STAR_STRUCK,
    STEPS_LOGO,
}

export default Images
