import baseApi, {
    transformResponseFromData,
    // transformResponseFromMessage,
    // provideTags,
    provideTag,
    getPaginationSearchAndSortString,
} from 'store/slices/api/baseApi'

const invitesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        resendInvite: build.mutation({
            query: ({inviteId}) => {
                console.log({inviteId}, 'RE SEND INVITE')
                return {
                    url: `invites/resend/${inviteId}`,
                    method: 'POST',
                    body: {},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Invite'],
        }),
        sendInvite: build.mutation({
            query: (invite) => {
                return {
                    url: 'invites',
                    method: invite?.id ? 'PUT' : 'POST',
                    body: invite,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Invite'],
        }),
        sendBuklInvite: build.mutation({
            query: (params) => {
                return {
                    url: 'invites/users',
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Invite'],
        }),
        deleteInvite: build.mutation({
            query: (id) => {
                return {
                    url: `invites/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Invite'),
        }),
        getInvites: build.query({
            query: ({account_id, page, pageSize, search, sort}) => {
                const paginationSearchAndSortString =
                    getPaginationSearchAndSortString(
                        page,
                        pageSize,
                        search,
                        sort
                    )
                return {
                    url: `invites/${account_id}?${paginationSearchAndSortString.replace(
                        'search',
                        'ee'
                    )}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Invite'),
        }),
        getInvite: build.query({
            query: (id) => ({
                url: `invites/id/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => {
                return [provideTag('Invite', data)]
            },
        }),
        setUserPassword: build.mutation({
            query: (invite) => {
                return {
                    url: 'admin/auth/setUserPassword',
                    method: 'POST',
                    body: invite,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Invite'],
        }),
    }),
})

export default invitesApi

export const {
    useSendBuklInviteMutation,
    useSendInviteMutation,
    useGetInvitesQuery,
    useGetInviteQuery,
    useDeleteInviteMutation,
    useResendInviteMutation,
    useSetUserPasswordMutation,
} = invitesApi
