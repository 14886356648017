import baseApi, {
    provideTag,
    transformResponseFromData,
} from 'store/slices/api/baseApi'

const configApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getGlobalConfig: build.query({
            query: () => ({
                url: `admin/config`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('GlobalConfig'),
        }),
    }),
    overrideExisting: false,
})

export const {useGetGlobalConfigQuery} = configApi
