const he = {
    dir: 'rtl',
    header: {
        search: 'חיפוש',
    },
    hello: 'שלום',
    choose_an_account: 'לבחור חשבון',
    login: {
        header: 'ברוכים הבאים ל-Hygear!',
        sub_header:
            'הכניסו את כתובת המייל והסיסמה שלכם כדי להיכנס למערכת הניהול',
        email: 'מייל',
        password: 'סיסמה',
        confirm_password: 'וידוא סיסמא',
        forgot_password: 'שכחתי סיסמה',
        login_button: 'התחבר',
        submit_button: 'התחבר',
        forgot_password_header: 'שינוי סיסמה',
        forgot_password_button: 'שלח',
    },
    forgot_password: {
        header: 'שינוי סיסמה',
        sub_header: 'אנא הכניסו את המייל איתו התחברתם אל המערכת',
        user_not_found: 'כתובת המייל אינה מזוהה',
        email_sent: 'תודה! נשלח אליך מייל עם לינק להגדרת סיסמה חדשה',
        submit_button: 'התחבר',
    },
    reset_password: {
        header: 'שינוי סיסמה',
        password_changed: 'סיסמתך שונתה',
        action: 'התחבר',
        submit_button: 'התחבר',
    },
    register: {
        header: 'ברוך הבא, אנא הזין סיסמה',
        submit_button: 'שלח',
    },
    profileMenu: {
        changePassword: 'שנה סיסמה',
        adminsList: 'רשימת מנהלים',
        logout: 'התנתק',
    },
    menu: {
        dashboard: 'דשבורד',
        organizations: 'עמותות',
        organization: 'עמותה',
        animals: 'חיות',
        all_mail: 'דוא״ל',
        trash: 'זבל',
        spam: 'ספאם',
        users: 'משתמשים',
        accounts: 'חשבונות',
        weekly_view: 'מבט שבועי',
        monthly_view: 'מבט חודשי',
        groups: 'קבוצות',
        plans: 'אימונים',
        programs: 'תוכניות',
        rewards: 'תגמולים',
        challenges: 'אתגרים',
        settings: 'הגדרות',
        help: 'עזרה',
    },
    activity_diversity: {
        title: 'סוג פעילות',
        sub_title: 'הפעילויות הפופולריות ביותר',
        actions: 'פעילוית',
    },
    pulse: {
        title: 'דופק',
        sub_title: 'ניתוח פעילויות אירוביות לפי דופק ממוצע בפעילות',
        actions: 'פעילוית',
        activities_use: {
            inactive: 'לא פעיל',
            moderate: 'מתון',
            highly_active: 'פעיל מאוד',
        },
    },
    workout_plan: {
        title: 'תכנית אימונים',
        sub_title: '',
        categories: {
            power: 'כוח',
            aerobic: 'אירובי',
            steps: 'צעדים',
        },
        weekly_view: {
            title: '',
            sub_title: 'באיזה שיעור עומדים המשתתפים ביעדים היומיים',
        },
        monthly_view: {
            title: '',
            sub_title: 'מידת ההתמדה בארבעת השבועות האחרונים',
            xAxisTitle: 'שבוע %',
            yAxisTitle: 'אחוז משתתפים',
        },
        goal_rates: {
            all_goals: 'עמדו בכל היעדים',
            minimum_one_fulfilled: 'עמדו בלפחות יעד אחד',
            partial_goals: 'עמדו בחלק מהיעדים',
            none_fulfilled: 'החלו אך לא השלימו יעד',
        },

        accomplishments: {
            title: '',
            sub_title: 'באיזו מידה המשתתפים משלימים פעילויות שהחלו?',
            started: 'סה״כ פעילויות שהחלו',
            completed: 'סהכ פעילויות שהושלמו',
        },
    },
    strength_practice_quality: {
        legend: {
            above_target: 'חזרות בהתנגדות גבוהה מהיעד',
            below_target: 'חזרות בטווח היעד',
            in_target: 'חזרות שלא הגיעו ליעד',
        },
        title: 'איכות אימוני כוח',
        sub_title: 'התפלגות חזרות לפי יעד התנגדות',
    },
    activity_type: {
        legend: {
            geargo: 'GearGo',
            hyrope: 'HyRoap',
            rope: 'חבל קפיצה',
            ball: 'כדור',
            run: 'ריצה',
            bicycle: 'אופניים',
            walk: 'הליכה',
            other: 'אחר',
        },
    },
    form: {
        update: 'עדכון',
        create: 'יצירת',
        link: 'קישור',
        add: 'הוספת',
        keyError: 'נדרש/ת {{key}} חוקי/ת.',
        not: 'לא',
        send: 'שלח',
        submit: 'שלח',
        ok: 'אישור',
    },
    general: {
        invalid_password: 'סיסמה חייבת להיות בת 8 תוים לפחות',
        invalid_verify_password: 'הסיסמה לא תואמת את שדה הסיסמה הראשון',
    },
    tables: {
        users: 'משתמשים',
        accounts: 'חשבונות',
        table_title: '{{title}} ({{total}})',
        action_delete: 'מחיקה',
        action_edit: 'עריכה',
        pagination_page_size: ':מספר שורות בעמוד',
        pagination_total_text: '{{rangeStart}}-{{rangeEnd}} מתוך {{total}}',
    },
    accounts: {
        _id: 'ID',
        name: 'שם',
        apiKey: 'Api Key',
        created: 'נוצר',
        updated: 'נערך',
        role: 'תפקיד',
        confirmed: 'מאושר',
        image: 'סֵמֶל',
        contact_name: 'איש קשר',
        contact_email: 'אימייל קשר',
        username: 'שם משתמש',
        email: 'מייל',
        numberOfUsers: 'מספר משתמשים',
        add_button_text: 'הוספת חשבון',
        actives: 'Active Accounts',
        archiveds: 'Archived Accounts',
    },
    invites: {
        table_title: 'מוזמנים',
        status: 'סטטוס',
        firstName: 'שם פרטי',
        email: 'מייל',
        permissions: 'הרשאות',
        phone: 'טלפון',
        password: 'סיסמא',
        confirm_password: 'וידוא סיסמא',
        updated: 'תאריך הזמנה',
        inviteType: 'סוג הזמנה',
        idNumber: "מס' אישי",
        add_button_text: 'הזמנת משתמשים',
        user: 'משתמש',
        admin: 'מנהל',
        cellphone: 'טלפון נייד',
        add_form_title: 'הזמנה למערכת',
        create_password_form_title: 'Create Password',
        password_success_created: 'Password is successfully created',
        update_info: 'עדכון פרטים',
        send_reminder: 'שליחת תזכורת',
        import: 'יבוא מאקסל',
        manual_add: 'הוספה ידנית',
        invite_completed: 'ההזמנה הושלמה',
        invite_completed_success: 'נשלחו בהצלחה',
        invites: 'הזמנות',
        birthday: 'יום הולדת',
        height: 'גובה',
        weight: 'משקל',
        gender: 'מין',
        male: 'זכר',
        female: 'נקבה',
        shirtSize: 'גודל חולצה',
        functionalScore: 'ציון פונקציונלי',
        download_template: 'Download template',
        download_template_eng: 'Download English version template',
        edit_form_title: 'ערוך הזמנה',
        error_invalid_file: 'Invalid file, Please upload a CSV file',
        error_missing_fields: 'File is missing required headers',
        error_required_name: 'Name is required',
        error_string_name: 'Name must be a string',
        error_required_email: 'Email is required',
        error_valid_email: 'Email is no valid',
        error_valid_phone: 'Phone number please enter a valid number',
        error_valid_personal_number:
            'Personal number please enter a valid number',
        error_required_birthdate: 'Date of birth is required',
        error_valid_birthdate: 'Date of birth format should be YYYY-MM-DD',
        error_valid_gender: 'Gender must be Male, Female, or None.',
        error_valid_height: 'Height must be a number',
        error_valid_weight: 'Weight must be a number',
        error_valid_shirt_size:
            'Shirt size must be XS, S, M, L, XL, XXL, or XXXL.',
        error_valid_functional_score:
            'Functional Score must be between 1 and 5',
        error_upload_file: 'Please upload a file',
        error_upload_title: 'Your upload was failed',
        error_upload_description:
            'We were unable to process your file due to one or more errors. Please check and fix the errros below by re-upload your file.',
        error_upload_template:
            'Make sure you fill and upload file from the correct template',
        error_password_exists_empty: 'Password must be at least 8 characters!',
    },
    users: {
        users: 'רשומים',
        invites: 'מוזמנים',
        admins: 'מנהלים',
        trainings: 'Training Data',
        profiles: 'Personal Information',
        historys: 'Data History',
        table_title: 'רשומים',
        idNumber: "מס' אישי",
        firstName: 'שם פרטי',
        persistenceThisMonth: 'התמדה החודש',
        activeThisWeek: 'פעיל השבוע',
        program: 'תוכנית',
        grade: 'ציון תודעתי',
        email: 'כתובת מייל',
        abnormalHeartRate: 'דופק חריג',
        persistenceThisMonth_tooltip:
            'שיעור העמידה ביעדים ב-4 השבועות האחרונים',
        activeThisWeek_tooltip: 'עמד בלפחות יעד אחד השבוע',
        edit_form_title: 'עריכת נתונים',
        delete_confirm_modal_title: 'למחוק את המשתמש?',
        delete_confirm_modal_subtitle:
            'לאחר פעולה זו כל המידע הקיים על המשתמש במערכת ימחק ולא ניתן יהיה לשחזר אותו.',
        delete_confirm_modal_button_ok: 'כן, מחק',
        delete_confirm_modal_button_cancel: 'לא, אל תמחק',
    },
    programs: {},
    plans: {
        table_title: 'שלבי התוכנית',
        page_title: 'יצירת תוכנית אימונים',
        add_form_title: 'יצירת שלב בתוכנית האימונים',
        edit_form_title: 'עריכת שלב בתוכנית האימונים',
        page_sub_title:
            'תוכנית האימונים מורכבת מרצף שלבים בדרגות קושי עולות. כאן ניתן ליצור אותם ולהגדיר את משכם ותנאים להשלמתם. ',
        add_button_text: 'הוספת שלב לתכנית',
        add_button_disable_tooltip:
            'התוכנית הגיעה לשלב המקסימלי של 5. מחק פריט שלב אחד או יותר כדי להוסיף פריט חדש.',
        _id: 'ID',
        display_name: 'שם התוכנית',
        program: 'שם התוכנית',
        level: 'השלב בתוכנית',
        power_target: 'יעד כוח',
        aerobic_target: 'יעד אירובי',
        steps_target: 'יעד צעדים',
        level_form: 'מיקום התוכנית במערך האימונים',
        totalAerobicDaysTarget: 'יעד אירובי',
        totalPowerDaysTarget: 'יעד כוח',
        totalStepsDaysTarget: 'יעד צעדים',
        minimum_duration: 'משך מינימאלי',
        minimum_streak: 'רצף נדרש',
        program_error: 'בחר תכנית על מנת להמשיך',
        level_error: 'בחר שלב על מנת להמשיך',
        minimum_duration_error: 'בחר משך מינימאלי על מנת להמשיך',
        minimum_streak_error: 'בחר רצף נדרש על מנת להמשיך',
        start_date: 'תאריך התחלה של תוכנית',
        workouts: 'אימונים',
        weeks: {
            one: 'שבוע',
            other: '{{count}} שבועות',
        },
        add_confirm_modal_title: 'להוסיף את השלב לתוכנית?',
        add_confirm_modal_subtitle:
            'שים לב! עם הגדרת שלב זה, כלל משתמשי האפליקציה יחשפו אליו. בדוק היטב את הפרמטרים המוגדרים לפני האישור.',
        add_confirm_modal_button_ok: 'כן, צור את השלב',
        add_confirm_modal_button_cancel: 'לא, חזור לעריכה',
        edit_confirm_modal_title: 'לשנות את התוכנית?',
        edit_confirm_modal_subtitle:
            'שים לב! כל שינוי באחד משלבי התוכנית ישפיע על משתמשי האפליקציה. מומלץ להמנע משינויים בתוכנית הקיימת',
        edit_confirm_modal_button_ok: 'כן, שנה',
        edit_confirm_modal_button_cancel: 'לא, אל תשנה',
        delete_confirm_modal_title: 'למחוק את התוכנית?',
        delete_confirm_modal_subtitle:
            'שים לב! כל מחיקה  של אחד משלבי התוכנית תשפיע על משתמשי האפליקציה. מומלץ להמנע משינויים בתוכנית הקיימת',
        delete_confirm_modal_button_ok: 'כן, מחק',
        delete_confirm_modal_button_cancel: 'לא, אל תמחק',
        // apiKey: 'Api Key',
        // created: 'נוצר',
        // updated: 'נערך',
    },
    admins: {
        table_title: 'מנהלים',
        name: 'שם פרטי',
        email: 'מייל',
        permissions: 'הרשאות',
        permissions_option_admin: 'מלאות',
    },
    organizations: {
        _id: 'ID',
        created: 'נוצר',
        updated: 'נערך',
        description: 'תיאור',
        name: 'שם',
        order: 'סדר',
        address: 'כתובת',
        area: 'איזור',
        contact: 'איש קשר',
        email: 'דוא״ל',
        facebookUrl: 'קישור פייסבוק',
        homeUrl: 'אתר הבית',
        isactive: 'פעיל',
        lastActive: 'פעילות אחרונה',
        lastAdminMsg: 'הודעת אדמין אחרונה',
        logoUrl: 'לוגו',
        password: 'סיסמא',
        phone: 'טלפון',
        times: 'זמנים',
        username: 'שם משתמש',
    },
    usersFunnel: {
        registered: {title: 'רשומים', notes: 'הצטרפו לתכנית עד היום'},
        participants: {title: 'משתתפים', notes: 'נכנסו לאפליקצייה'},
        active: {title: 'פעילים', notes: 'השלימו לפחות יעד אחד'},
        excel: {title: 'מצטיינים', notes: 'השלימו את כל יעדי'},
        thisweek: ' השבוע ',
        thismonth: ' החודש ',
    },
    dashboard: {
        switchView: {
            subscribers: 'כל הרשומים',
            participants: ' רק משתתפים',
        },
        viewPeriod: {
            week: 'מבט שבועי',
            month: 'מבט חודשי',
        },
        workoutCategories: {
            power: 'כוח',
            strength: 'כוח',
            aerobic: 'אירובי',
            steps: 'צעדים',
        },
        bullets: {
            all: 'עמדו בכל היעדים',
            minimum: 'עמדו בלפחות יעד אחד',
            partial: 'עמדו בחלק מהיעדים',
            none: 'החלו אך לא השלימו יעד',
            never: 'Did not start any workout',
            geargo: 'GearGo',
            rope: 'HyRope',
            hyrope: 'HyRope',
            ball: 'כדור',
            run: 'ריצה',
            bicycle: 'אופניים',
            walk: 'הליכה',
            other: 'אחר',
            above_target: 'חזרות בהתנגדות גבוהה מהיעד',
            below_target: 'חזרות שלא הגיעו ליעד',
            in_target: 'חזרות בטווח היעד',
        },
    },
    fileUpload: {
        altMessage: 'לחץ להוסיף קובץ',
        requirementMessage:
            'Max 10 MB | PNG or JPEG | Size : 987px X 1170px | Minimum of 72 dpi',
    },
    imageCell: {
        altMessage: 'חסר לינק לתמונה',
    },
    rewards: {
        rewards: 'מוצרים',
        purchases: 'מוצרים לשליחה',
        sents: 'נשלחו',
        table_title: 'מוצרים',
        image: '',
        name: 'המוצר',
        description: 'תיאור',
        coin_required: 'שווי  ',
        level_requirement: 'תנאי לרכישה',
        created: 'ת. הוספת מוצר',
        is_displayed: 'מוצג בחנות',
        add_button_text: 'הוספת מוצר',
        add_form_title: 'הוספת מוצר',
        add_button_disable_tooltip:
            'לא זוהה שלב בתוכנית. אנא הוסף שלב תוכנית בדף תוכניות',
        edit_form_title: 'עריכת מוצר',
        delete_confirm_modal_title: 'למחוק את המוצר?',
        delete_confirm_modal_subtitle:
            'אחרי מחיקת המוצר לא ניתן יהיה עוד להציג אותו בחנות ',
        delete_confirm_modal_button_ok: 'כן, מחק',
        delete_confirm_modal_button_cancel: 'לא, אל תמחק',
    },
    product: {
        name: 'שם המוצר',
        short_name: 'שם קצר של המוצר',
        description: 'תיאור',
        image: '* תמונה',
        value: 'שווי המוצר בש״ח',
        purchaseRequirement: 'תנאי לרכשיה בחנות (לפי שלבי התוכנית)',
        is_displayed: 'להציג מוצר בחנות?',
    },
    purchases: {
        table_title: 'מוצרים לשליחה',
        image: '',
        checkbox: '',
        name: 'המוצר',
        purchase_date: 'תאריך רכישה',
        buyers_name: 'שם הרוכש',
        personal_number: 'מספר אישי',
        email: 'כתובת מייל',
        size: 'מידת חולצה',
        collection_point: 'נקודת חלוקה',
        add_button_text: 'העבר לנשלחו',
    },
    sents: {
        table_title: 'נשלחו',
        image: '',
        name: 'המוצר',
        purchase_date: 'תאריך רכישה',
        buyers_name: 'שם הרוכש',
        personal_number: 'מספר אישי',
        email: 'כתובת מייל',
        size: 'מידת חולצה',
        collection_point: 'נקודת חלוקה',
    },
}

export default he
