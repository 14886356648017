const PRODUCTS = {
    data: [
        {
            name: 'Blue nike shirt',
            description: 'Light training shirt for men with nike logo',
            image: 'htttps://image.url',
            coin_required: 50,
            product_value: 250,
            level_requirement: 1,
            is_displayed: true,
            available_sizes: ['S', 'M', 'L'],
            deleted: false,
            _id: '648049c87875451ef97ba1e3',
            short_name: 'blue_nike_shirt',
            created: '2023-06-07T09:11:36.381Z',
            updated: '2023-06-07T09:11:36.383Z',
            __v: 0,
        },
        {
            name: 'Hoka running shoes',
            description: 'Black running shoes for men',
            image: 'htttps://image.url',
            coin_required: 270,
            product_value: 1350,
            level_requirement: 2,
            is_displayed: false,
            available_sizes: [],
            deleted: false,
            _id: '64804ab97875451ef97ba21c',
            short_name: 'hoka_running_shoes',
            created: '2023-06-07T09:15:37.325Z',
            updated: '2023-06-07T09:15:37.326Z',
            __v: 0,
        },
    ],
}

export default PRODUCTS
