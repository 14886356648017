import 'app_globals'
import React from 'react'
import './App.css'
import NavigationRouter from 'navigation/NavigationRouter'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import * as Sentry from '@sentry/react'

import {store, persistor} from 'store/Store'
import {setLocale} from 'localization/i18n'
import storage from 'services/storage'

if (window.location.hostname !== 'localhost') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.25,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            // 'localhost',
            /^https:\/\/dev-api\.hyfitgear\.com\/api\/v1/,
        ],
    })
}

function App() {
    // Temporary fix
    // there are multiple data sources for language setting,
    // 1st: localStorage key '@hygear-admin:LANGUAGE'
    // 2nd: redux store, stored in localStorage via redux persist.
    // TODO: refactor, should use single source of truth for language setting
    let activeLanguage = storage.getLanguage()
    if (!activeLanguage) {
        storage.setLanguage('he')
        activeLanguage = 'he'
    }
    setLocale(activeLanguage)

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <NavigationRouter />
            </PersistGate>
        </Provider>
    )
}

export default App
