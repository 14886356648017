export const DATE_VIEW_PERIODS = ['week', 'month']
export const WORKOUT_GOALS = ['power', 'aerobic', 'steps']
export const NEW_WORKOUT_GOALS = ['strength', 'aerobic', 'steps']
export const GOAL_TYPES = ['all', 'partial', 'none']

export const WORKOUT_GOAL_ACCOMPLISHMENT = {
    all_goals: 'all_goals',
    partial_goals: 'partial_goals',
    none_fulfilled: 'none_fulfilled',
    achieved: 'achieved',
    did_not_achieve: 'did_not_achieve',
}

export const ACTIVITIES_TYPES = [
    // 'geargo', To achive ticket HYGE-24 sprint-1
    // 'hyrope',
    'ball',
    'run',
    'bicycle',
    'walk',
    'other',
]
export const STRENGTH_PRACTICE_QUALITY = [
    'above_target',
    'in_target',
    'below_target',
]
export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
