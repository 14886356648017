import {Route} from 'react-router-dom'
import {Redirect, useDispatch} from 'services/hooks'
import React from 'react'
import {
    setMonthlyRange,
    setWeeklyRange,
    useGetCurrentUserQuery,
    useLogoutMutation,
} from 'store/Store'
import {getMonthly, getWeekly} from 'services/utils/dashboard'

const ProtectedRoute = ({component: Component, ...restOfProps}) => {
    const {data, error, isLoading} = useGetCurrentUserQuery()

    if (isLoading) {
        return null
    }

    const renderProtectedComp = (prop) => {
        if (restOfProps.path === '/accounts' && !data?.isSuperAdmin)
            return <Redirect to="/dashboard" from={restOfProps.path} />
        else if (!data?.email && error?.status === 401)
            return <Redirect to="/login" />
        else return <Component {...prop} from={restOfProps.path} />
    }

    return (
        <Route
            {...restOfProps}
            render={(props) => renderProtectedComp(props)}
        />
    )
}

const OutRoute = ({component: Component, ...restOfProps}) => {
    let nextRoute = 'login'

    const {data, error} = useGetCurrentUserQuery()
    const [doLogout] = useLogoutMutation()
    const dispatch = useDispatch()

    const weeklyDate = getWeekly()
    const monthlyDate = getMonthly()

    if (data) {
        dispatch(setWeeklyRange(weeklyDate))
        dispatch(setMonthlyRange(monthlyDate))

        if (data?.role === 1)
            sessionStorage.setItem('accountId', data?.accountId)
        if (data.isSuperAdmin || data?.role !== 0)
            nextRoute = data.isSuperAdmin ? '/accounts' : '/dashboard'
        else {
            dispatch(setWeeklyRange())
            dispatch(setMonthlyRange())
            doLogout()
            window.location.replace('/login')
        }
    }

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                !data?.email || error?.status.includes([401, 500]) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={
                            nextRoute === '/dashboard'
                                ? {
                                      pathname: nextRoute,
                                      state: {from: restOfProps.path},
                                  }
                                : nextRoute
                        }
                    />
                )
            }
        />
    )
}

const RouteManager = {ProtectedRoute, OutRoute}

export default RouteManager
