import axios from 'axios'
import storage from './storage'

const parts = window.location.hostname.split('.')
const subdomainHost = parts.shift()
const isb2bAdmin = subdomainHost === 'b2b-admin'

const SERVER_URL =
    process.env.REACT_APP_API_HOST ||
    window.location.protocol +
        '//' +
        window.location.host.replace('admin', 'api')

const BASE_URL = isb2bAdmin
    ? 'https://api.hyfitgear.com/api/v1'
    : SERVER_URL + '/api/v1' //'http://localhost:3300/'

const HEADERS = {
    'Content-Type': 'application/json',
    'hyfit-version': process.env.REACT_APP_HYFIT_VERSION,
}

class Api {
    constructor() {
        this.baseUrl = BASE_URL
    }

    async request(
        url,
        method,
        params,
        data,
        headers = {},
        onSuccess
        // rememberMe = storage.getRememberMe()
    ) {
        const rememberMe = storage.getRememberMe()
        try {
            const response = await axios.request({
                headers: {...HEADERS, ...headers},
                withCredentials: true,
                baseURL: this.baseUrl,
                method,
                url,
                data,
                params,
            })

            // console.log('axios response', {response})
            onSuccess && onSuccess(response?.data)
            return response?.data
        } catch (error) {
            console.error('axios error', {error})
            // onError && (await onError(error))
            if (
                error?.status === 401 &&
                url !== 'auth/refreshToken' &&
                rememberMe === 'true'
            ) {
                return await this.reLoginWithRefreshToken(error)
            }
        }
    }

    async addUser(email, password, name) {
        return this.request('users/signup', 'POST', null, {
            email,
            password,
            name,
        })
    }

    async getCurrentUser() {
        return this.request(`auth/getCurrentUser`, 'GET')
    }
    async login(email, password) {
        return this.request(`auth/login`, 'POST', null, {email, password})
    }

    async logout() {
        return this.request('auth/logout', 'POST')
    }

    async uploadFile(formData, bucket, folder) {
        return this.request('/upload', 'POST', {bucket, folder}, formData, {
            'Content-Type': 'multipart/form-data',
            ...formData.getHeaders(),
        })
    }

    async forgotPassword(email) {
        return this.request('auth/forgotPassword', 'POST', null, {email})
    }

    async refreshToken() {
        return this.request('auth/refreshToken', 'POST')
    }

    async test() {
        return this.request('auth/refreshToken', 'POST')
    }

    async reLoginWithRefreshToken(err) {
        const originalReq = err.config
        // console.log('message: ', originalReq)

        if (
            err.response?.status === 401 &&
            originalReq.url === 'auth/refreshToken'
        ) {
            return
        }

        originalReq._retry = true
        // console.log('message: ', 'mid')
        // console.log('class: ', this)

        try {
            await this.refreshToken()
            return await this.request(
                originalReq.url,
                originalReq.method,
                originalReq.params,
                originalReq.data,
                originalReq.headers,
                null,
                null
            )
        } catch (err) {
            console.error('refresh error: ', err)
            // window.location.href = '/login'
        }
    }
}
const api = new Api()
export default api
