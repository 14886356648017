const PURCHASE_HISTORY = {
    data: [
        {
            name: 'Blue nike shirt',
            description: 'Light training shirt for men with nike logo',
            image: 'htttps://image.url',
            coin_required: 50,
            product_value: 250,
            level_requirement: 1,
            is_displayed: true,
            deleted: false,
            _id: '648049c87875451ef97ba1e3',
            short_name: 'blue_nike_shirt',
            created: '2023-06-07T09:11:36.381Z',
            updated: '2023-06-07T09:11:36.383Z',
            __v: 0,
            purchase_date: '2023-06-10T09:11:36.381Z',
            buyers_name: 'Ariela',
            personal_number: '7294846',
            email: 'Urielalav111@gmail.com',
            size: '',
            collection_point: '',
        },
        {
            name: 'Sport bottle',
            description: 'Light training shirt for men with nike logo',
            image: 'htttps://image.url',
            coin_required: 50,
            product_value: 250,
            level_requirement: 1,
            is_displayed: true,
            deleted: false,
            _id: '648049c87875451ef97ba1e4',
            short_name: 'sport_bottle',
            created: '2023-06-07T09:11:36.381Z',
            updated: '2023-06-07T09:11:36.383Z',
            __v: 0,
            purchase_date: '2023-06-10T09:11:36.381Z',
            buyers_name: 'Dodo',
            personal_number: '7294846',
            email: 'dodo@gmail.com',
            size: '',
            collection_point: '',
        },
    ],
}

export default PURCHASE_HISTORY
