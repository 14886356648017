import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import {composeWithDevTools} from 'redux-devtools-extension'

// import {createLogger} from 'redux-logger'

import baseApi from './slices/api/baseApi'
import {appSlice} from './slices/app'

import rtkQueryErrorHandler from './middleware/rtkQueryErrorHandler'

// create and filter specific actions
// const logger = createLogger({
//     predicate: (getState, action) => action.type !== 'SET_PROCESSING',
// })

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, appSlice.reducer)

const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        app: persistedReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false})
            // .concat(logger)
            .concat(baseApi.middleware)
            .concat(rtkQueryErrorHandler),
    // devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)
const persistor = persistStore(store)

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export const RootState = typeof store.getState
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export const AppDispatch = typeof store.dispatch

export * from './slices/api/authApi'
export * from './slices/api/accountsApi'
export * from './slices/api/usersApi'
export * from './slices/api/invitesApi'
export * from './slices/api/rewardsApi'
export * from './slices/api/configApi'
export * from './slices/app'

export {store, persistor}
